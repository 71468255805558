<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
import Cookies from 'js-cookie'
import config from '@/config/config.js'
import qs from 'qs'
export default {
    async mounted () {
        if (process.env.VUE_APP_PRODUCT !== 'cuiqiu_domain_pri') {
            this.$store.commit('setCorpCss', {
                corp_name: process.env.VUE_APP_TITLE,
                logo: '',
                bg: require('./assets/bg.jpg')
            })
        }

        this.initInvite()
    },
    methods: {
        initInvite () {
            // 绑定邀请
            const url = window.location.search
            const urlArr = url.split('?')
            if (urlArr.length < 2) {
                return
            }
            const { c = '' } = qs.parse(urlArr[1])
            if (c) {
                const invite = Cookies.get(config.COOKIES.INVITE)
                if (!invite) {
                    Cookies.set(config.COOKIES.INVITE, c, { domain: config.COOKIES.SET_DOMAIN })
                }
            }

            // 缓存URL
            const mailLocation = Cookies.get(config.COOKIES.LOCATION)
            if (!mailLocation) {
                Cookies.set(config.COOKIES.LOCATION, window.location.href, { domain: config.COOKIES.SET_DOMAIN })
            }

            // 缓存Referrer
            const cuiqiuMailReferrer = Cookies.get(config.COOKIES.REFERRER)
            if (!cuiqiuMailReferrer) {
                Cookies.set(config.COOKIES.REFERRER, document.referrer, { domain: config.COOKIES.SET_DOMAIN })
            }
        }
    }
}
</script>
<style>
*{
    padding: 0;
    margin: 0;
}
a{
    color: #717f99;
    text-decoration: unset;
}
a:hover{
    color: #ff4800;
}
</style>
