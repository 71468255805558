import { render, staticRenderFns } from "./weixin-button.vue?vue&type=template&id=4db995a8&scoped=true"
import script from "./weixin-button.vue?vue&type=script&lang=js"
export * from "./weixin-button.vue?vue&type=script&lang=js"
import style0 from "./weixin-button.vue?vue&type=style&index=0&id=4db995a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db995a8",
  null
  
)

export default component.exports