<template>
    <Layout>
        <div style="display: flex;justify-content: center;padding:50px 0;align-items: center;width: 100%;">
            <div style="width:80%;">
                <p style="text-align:center;padding:10px 0;">{{$t("password_reset.title")}}</p>
                <div style="padding-bottom:10px;">
                    <el-input v-model="forget_data.tel_num" :placeholder="$t('qing-shu-ru-shou-ji')" v-if="VUE_APP_PROJECT==='cuiqiu'">
                        <div slot="prepend">
                            <i class="el-icon-phone" />
                            {{ $t('shou-ji-hao') }}
                        </div>
                    </el-input>
                </div>
                <div style="padding-bottom:10px;">
                    <el-input v-model="forget_data.mail" :placeholder="$t('input.placeholder.mail')" v-if="VUE_APP_PROJECT==='getmx'">
                        <div slot="prepend">
                            <i class="el-icon-message" />
                            {{$t("input.lable.mail")}}
                        </div>
                    </el-input>
                </div>

                <div style="padding-bottom:10px;">
                    <el-input v-model="forget_data.verify_code" :placeholder="$t('input.placeholder.verify_code')">
                        <div slot="prepend">
                            <i class="el-icon-message" />
                            {{$t("input.lable.verify_code")}}
                        </div>
                        <div v-if="forget_data.send_status === 1" slot="append" style="cursor:pointer;">
                            <div style="width:100%;height:100%;" @click="sendCode('forget_data')">
                                {{$t("button.verify_code_send")}}
                            </div>
                        </div>
                        <div v-if="forget_data.send_status === 2" slot="append">
                            <i class="el-icon-loading" />
                        </div>
                        <div v-if="forget_data.send_status === 3" slot="append">
                            {{ forget_data.send_text }}
                        </div>
                    </el-input>
                </div>

                <div style="padding-bottom:10px;">
                    <el-input v-model="forget_data.password_1" :placeholder="$t('input.placeholder.password')" show-password>
                        <div slot="prepend">
                            <i class="el-icon-key" />
                            {{$t('input.placeholder.password')}}
                        </div>
                    </el-input>
                </div>

                <div style="padding-bottom:10px;">
                    <el-input v-model="forget_data.password_2" :placeholder="$t('input.placeholder.password_2')" show-password>
                        <div slot="prepend">
                            <i class="el-icon-key" />
                            {{$t('input.placeholder.password_2')}}
                        </div>
                    </el-input>
                </div>

                <div style="padding-bottom:10px;">
                    <el-button type="danger" size="medium" style="width:100%;" @click="resetPassword">
                        {{$t('button.password_reset')}}
                    </el-button>
                </div>

                <div style="text-align:center;">
                    <router-link :to="{ name: 'login' }">
                        {{ $t('fan-hui-deng-lu-zhang-hao') }}
                    </router-link>
                </div>

            </div>
        </div>
        <!-- breadcrumb-area-end -->
    </Layout>
</template>

<script>
import Cookies from 'js-cookie'
import { sendCode, resetPassword, getUserInfo } from '@/api/user'
import Layout from '@/components/Layout.vue'
import config from '@/config/config.js'
import { CaptchaCode } from '@/utils/captcha'
import { InitCorpDetail } from '@/utils/pri'
import { IsMail, IsTel } from '@/utils/verify'
export default {
    components: { Layout },
    name: 'ResetPassword',
    data () {
        return {
            VUE_APP_PROJECT: process.env.VUE_APP_PROJECT,
            VUE_APP_PRODUCT: process.env.VUE_APP_PRODUCT,
            // 重置密码数据
            forget_data: {
                mail: '',
                tel_num: '',
                verify_code: '',
                password_1: '',
                password_2: '',
                send_text: this.$t('button.verify_code_send'),
                send_status: 1,
                send_product: 'reset-password'
            },
            login_status: 0
        }
    },
    async mounted () {
        if (process.env.VUE_APP_PRODUCT === 'cuiqiu_domain_pri') {
            try {
                await InitCorpDetail()
            } catch (err) {
                this.$message({ message: err, type: 'error' })
            }
        }
        this.initUserInfo()
    },
    methods: {
        async initUserInfo () {
            const token = Cookies.get(config.COOKIES.TOKEN)
            if (!token) {
                this.login_status = -1
                return
            }
            this.token = Cookies.get(config.COOKIES.TOKEN)
            try {
                const userResponse = await getUserInfo({})
                const { user = '' } = userResponse.data || {}
                const { tel_num = '', mail = '', status = 0 } = user
                if (status === 1) {
                    this.forget_data.tel_num = tel_num
                    this.forget_data.mail = mail
                    this.login_status = 1
                } else {
                    this.login_status = -1
                }
            } catch (err) {
                console.error('getUserInfo Err', err)
                this.$message.error(err || this.$t('wu-fa-huo-qu-yong-hu-xin-xi'))
            }
        },
        // 重置密码
        async resetPassword () {
            if (this.VUE_APP_PROJECT === 'cuiqiu') {
                if (!IsTel(this.forget_data.tel_num)) {
                    this.$message.error(this.$t('shou-ji-ge-shi-bu-zheng-que'))
                    return
                }
            }
            if (this.VUE_APP_PROJECT === 'getmx') {
                if (!IsMail(this.forget_data.mail)) {
                    this.$message.error(this.$t('input.verify.mail_format'))
                    return
                }
            }
            if (this.forget_data.password_1 === '') {
                this.$message.error(this.$t('input.verify.password_empty'))
                return
            }
            if (this.forget_data.password_1 !== this.forget_data.password_2) {
                this.$message.error(this.$t('input.verify.password_diff'))
                return
            }

            try {
                await resetPassword({
                    tel_num: this.forget_data.tel_num,
                    mail: this.forget_data.mail,
                    verify_code: this.forget_data.verify_code,
                    password: this.forget_data.password_1,
                    product: 'reset-password'
                })
                Cookies.remove(config.COOKIES.TOKEN, { domain: config.COOKIES.SET_DOMAIN })
                this.$message.success(this.$t('message.update_success'))
                this.$router.push({ name: 'login' })
            } catch (err) {
                console.error('resetPassword Err', err)
                this.$message.error(err || this.$t('message.update_fail'))
            }
        },

        // 倒计时
        lastTime (data) {
            let time = 60
            const lastInterval = setInterval(() => {
                this[data].send_status = 3
                if (time <= 0) {
                    clearInterval(lastInterval)
                    this[data].send_status = 1
                    this[data].send_text = this.$t('button.verify_code_send')
                    return
                }
                time--
                this[data].send_text = `${time}s`
            }, 1000)
        },

        // 发送验证码
        async sendCode (data) {
            this[data].send_status = 2
            if (this.VUE_APP_PROJECT === 'cuiqiu' && !IsTel(this[data].tel_num)) {
                this[data].send_status = 1
                this.$message.error(this.$t('shou-ji-ge-shi-bu-zheng-que'))
                return
            }
            if (this.VUE_APP_PROJECT === 'getmx' && !IsMail(this[data].mail)) {
                this[data].send_status = 1
                this.$message.error(this.$t('input.verify.mail_format'))
                return
            }

            // 验证码
            let captchaData = {}
            try {
                captchaData = await CaptchaCode()
            } catch (err) {
                console.error('CaptchaCode Err:' + err)
                this[data].send_status = 1
                this.$message.error(err || this.$t('message.verify_code_fail'))
                throw err
            }

            const { captcha_id, captcha_value } = captchaData || {}

            try {
                await sendCode({
                    captcha_id,
                    captcha_value,
                    tel_num: this[data].tel_num,
                    mail: this[data].mail,
                    product: this[data].send_product
                })
                this.lastTime(data)
            } catch (err) {
                this[data].send_status = 1
                console.error('sendCode err', err)
                this.$message.error(err || this.$t('message.send_code_fail'))
            }
        }
    }
}
</script>

<style scoped>

</style>
