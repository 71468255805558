import request from '@/utils/request'

// 登录
export function login (data) {
    return request({
        method: 'post',
        path: '/v1/user/login',
        params: data
    })
}

export function regist (data) {
    return request({
        method: 'post',
        path: '/v1/user/regist',
        params: data
    })
}

export function sendCode (data) {
    return request({
        method: 'post',
        path: '/v1/user/code/send',
        params: data
    })
}

export function resetPassword (data) {
    return request({
        method: 'post',
        path: '/v1/user/password/update',
        params: data
    })
}

export function updateTelNumOrMail (data) {
    return request({
        method: 'post',
        path: '/v1/user/tel-num-mail/update',
        params: data
    })
}

export function updateVerify (data) {
    return request({
        method: 'post',
        path: '/v1/user/verify/update',
        params: data
    })
}

export function getUserInfo (data) {
    return request({
        path: '/v1/user/detail',
        params: data
    })
}

export function logout (data) {
    return request({
        method: 'post',
        params: { ...data, action: 'logout' }
    })
}

export function checkVerify (data) {
    return request({
        path: '/v1/user/verify/check',
        params: data
    })
}

export function checkUserLocal (data) {
    return request({
        path: '/v1/user/local',
        params: data
    })
}
