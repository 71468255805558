// 脆球域名邮箱
if (process.env.VUE_APP_PRODUCT === 'cuiqiu_auth') {
    if (process.env.NODE_ENV === 'development') {
        module.exports = {
            API_URL: 'http://localhost:6215',
            // API_URL: 'https://mail-api-dev.cuiqiu.com',
            ADMIN_INDEX: 'http://localhost:9002',
            COOKIES: {
                SET_DOMAIN: 'localhost',
                TOKEN: 'cuiqiu-token',
                LOGIN_TEL_NUM_MAIL: 'cuiqiu-tel-num',
                INVITE: 'cuiqiu-invite',
                LOCATION: 'cuiqiu-mail-location',
                REFERRER: 'cuiqiu-mail-referrer'
            }
        }
    } else if (process.env.NODE_ENV === 'test') {
        module.exports = {
            API_URL: 'https://auth-api-test.cuiqiu.com',
            ADMIN_INDEX: 'https://mail-test.cuiqiu.com',
            COOKIES: {
                SET_DOMAIN: 'cuiqiu.com',
                TOKEN: 'cuiqiu-token',
                LOGIN_TEL_NUM_MAIL: 'cuiqiu-tel-num',
                INVITE: 'cuiqiu-invite',
                LOCATION: 'cuiqiu-mail-location',
                REFERRER: 'cuiqiu-mail-referrer'
            }
        }
    } else {
        module.exports = {
            API_URL: 'https://auth-api.cuiqiu.com',
            ADMIN_INDEX: 'https://mail-admin.cuiqiu.com',
            COOKIES: {
                SET_DOMAIN: 'cuiqiu.com',
                TOKEN: 'cuiqiu-token',
                LOGIN_TEL_NUM_MAIL: 'cuiqiu-tel-num',
                INVITE: 'cuiqiu-invite',
                LOCATION: 'cuiqiu-mail-location',
                REFERRER: 'cuiqiu-mail-referrer'
            }
        }
    }
}

// 私有化部署
if (process.env.VUE_APP_PRODUCT === 'cuiqiu_auth_pri') {
    if (process.env.NODE_ENV === 'development') {
        module.exports = {
            API_URL: 'http://localhost:6215/api',
            ADMIN_INDEX: 'http://localhost:9002',
            PRI_REQUEST_API: 'https://pri-auth-api-test.cuiqiu.com/v1/corp/detail',
            ENMOOY_URL: 'https://test-account.enmooy.com',
            AUTH_CALLBACK_URL: 'http://localhost:9003',
            COOKIES: {
                SET_DOMAIN: 'localhost',
                TOKEN: 'cuiqiu-token',
                LOGIN_TEL_NUM_MAIL: 'cuiqiu-tel-num',
                INVITE: 'cuiqiu-invite',
                LOCATION: 'cuiqiu-mail-location',
                REFERRER: 'cuiqiu-mail-referrer',
                CORP_ID: 'cuiqiu-pri-corp-id'
            }
        }
    } else if (process.env.NODE_ENV === 'test') {
        module.exports = {
            API_URL: '',
            ADMIN_INDEX: 'https://mail-admin-pri-test.cuiqiu.com',
            PRI_REQUEST_API: 'https://pri-domain-api-test.cuiqiu.com/v1/corp/detail',
            ENMOOY_URL: 'https://test-account.enmooy.com',
            AUTH_CALLBACK_URL: 'http://mail-admin-pri-test.cuiqiu.com',
            COOKIES: {
                SET_DOMAIN: 'cuiqiu.com',
                TOKEN: 'cuiqiu-token',
                LOGIN_TEL_NUM_MAIL: 'cuiqiu-tel-num',
                INVITE: 'cuiqiu-invite',
                LOCATION: 'cuiqiu-mail-location',
                REFERRER: 'cuiqiu-mail-referrer',
                CORP_ID: 'cuiqiu-pri-corp-id'
            }
        }
    } else {
        module.exports = {
            API_URL: '',
            ADMIN_INDEX: 'https://mail-admin.cuiqiu.cn',
            PRI_REQUEST_API: 'https://domain-api.cuiqiu.cn/v1/corp/detail',
            ENMOOY_URL: 'https://account.enmooy.com',
            AUTH_CALLBACK_URL: 'https://mail-admin.cuiqiu.cn',
            COOKIES: {
                SET_DOMAIN: 'cuiqiu.com',
                TOKEN: 'cuiqiu-token',
                LOGIN_TEL_NUM_MAIL: 'cuiqiu-tel-num',
                INVITE: 'cuiqiu-invite',
                LOCATION: 'cuiqiu-mail-location',
                REFERRER: 'cuiqiu-mail-referrer',
                CORP_ID: 'cuiqiu-pri-corp-id'
            }
        }
    }
}

// GetMX
if (process.env.VUE_APP_PRODUCT === 'getmx_auth') {
    if (process.env.NODE_ENV === 'development') {
        module.exports = {
            API_URL: 'http://localhost:6215',
            // API_URL: 'https://mail-api-dev.getmx.com',
            ADMIN_INDEX: 'http://localhost:9002',
            COOKIES: {
                SET_DOMAIN: 'localhost',
                TOKEN: 'getmx-token',
                LOGIN_TEL_NUM_MAIL: 'getmx-mail',
                INVITE: 'getmx-invite',
                LOCATION: 'getmx-mail-location',
                REFERRER: 'getmx-mail-referrer'
            }
        }
    } else if (process.env.NODE_ENV === 'test') {
        module.exports = {
            API_URL: 'https://auth-api-test.getmx.com',
            ADMIN_INDEX: 'https://mail-test.getmx.com',
            COOKIES: {
                SET_DOMAIN: 'getmx.com',
                TOKEN: 'getmx-token',
                LOGIN_TEL_NUM_MAIL: 'getmx-mail',
                INVITE: 'getmx-invite',
                LOCATION: 'getmx-mail-location',
                REFERRER: 'getmx-mail-referrer'
            }
        }
    } else {
        module.exports = {
            API_URL: 'https://auth-api.getmx.com',
            ADMIN_INDEX: 'https://mail-admin.getmx.com',
            COOKIES: {
                SET_DOMAIN: 'getmx.com',
                TOKEN: 'getmx-token',
                LOGIN_TEL_NUM_MAIL: 'getmx-mail',
                INVITE: 'getmx-invite',
                LOCATION: 'getmx-mail-location',
                REFERRER: 'getmx-mail-referrer'
            }
        }
    }
}
