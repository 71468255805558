import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import regist from '../views/regist.vue'
import passwordReset from '../views/password-reset.vue'
import loginCallback from '../views/login-callback.vue'
import Reset from '../views/reset.vue'
import verify from '../views/verify.vue'
import store from '@/store'
import i18n from '@/lang/index.js'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: login,
        meta: {
            title: i18n.t('login.title')
        }
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        meta: {
            title: i18n.t('login.title')
        }
    },
    {
        path: '/login-callback',
        name: 'login-callback',
        component: loginCallback,
        meta: {
            title: i18n.t('deng-lu-jie-guo')
        }
    },
    {
        path: '/regist',
        name: 'regist',
        component: regist,
        meta: {
            title: i18n.t('regist.title')
        }
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: passwordReset,
        meta: {
            title: i18n.t('button.password_reset')
        }
    },
    {
        path: '/reset',
        name: 'reset',
        component: Reset,
        meta: {
            title: i18n.t('xiu-gai')
        }
    },
    {
        path: '/verify',
        name: 'verify',
        component: verify,
        meta: {
            title: i18n.t('deng-lu-er-ci-yan-zheng')
        }
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (process.env.VUE_APP_PROJECT === 'cuiqiu_auth_pri') {
        const { corp_name = '' } = store.state.corpCss
        document.title = corp_name === '' ? to.meta.title : `${to.meta.title}-${corp_name}`
    } else {
        document.title = to.meta.title
    }
    next()
})

export default router
