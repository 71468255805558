<template>
    <Layout>
        <div style="display: flex;justify-content: center;padding:50px 0;align-items: center;width: 100%;">
            <div style="width:80%;" v-if="login_status === -1">

                <!-- 登录 -->
                <p style="text-align:center;padding:10px 0;">{{$t("common.no_login")}}</p>

                <div style="padding:30px 0px;">
                    <router-link :to="{ name: 'login' }">
                        <el-button type="warning" size="medium" style="width:100%;">
                            {{$t("button.login")}}
                        </el-button>
                    </router-link>
                </div>

            </div>

            <div style="width:80%;" v-if="login_status === 1">

                <!-- 登录 -->

                <p v-if="VUE_APP_PROJECT==='cuiqiu'" style="text-align: center;font-size: 30px;color: #e7a23b;">
                    {{login_data.tel_num}}
                </p>
                <p v-if="VUE_APP_PROJECT==='getmx'" style="text-align: center;font-size: 30px;color: #e7a23b;">
                    {{login_data.mail}}
                </p>

                <p style="text-align:center;padding:10px 0;">{{ login_data.verify_status_computed ? $t("verify_two.status_open"):$t("verify_two.status_close") }}
                </p>
                <p style="text-align: center;">
                    {{$t("verify_two.title")}}
                    <el-switch v-model="login_data.verify_status_computed" @change="verifyStatusChange"
                               active-color="#13ce66">
                    </el-switch>
                </p>

            </div>
        </div>
        <!-- breadcrumb-area-end -->
    </Layout>
</template>

<script>
import Cookies from 'js-cookie'
import { updateVerify, getUserInfo } from '@/api/user'
import Layout from '@/components/Layout.vue'
import config from '@/config/config.js'
import { InitCorpDetail } from '@/utils/pri'
export default {
    components: { Layout },
    name: 'Verify',
    computed: {

    },
    data () {
        return {
            VUE_APP_PROJECT: process.env.VUE_APP_PROJECT,
            VUE_APP_PRODUCT: process.env.VUE_APP_PRODUCT,
            // 登录数据
            login_data: {
                tel_num: '',
                mail: '',
                verify_status_computed: false
            },
            login_status: 0
        }
    },
    async mounted () {
        if (process.env.VUE_APP_PRODUCT === 'cuiqiu_domain_pri') {
            try {
                await InitCorpDetail()
            } catch (err) {
                this.$message({ message: err, type: 'error' })
            }
        }
        this.initUserInfo()
    },
    methods: {
        async verifyStatusChange (value) {
            try {
                await updateVerify({
                    verify_status: (value ? 1 : -1)
                })
                this.initUserInfo()
                this.$message.success(this.$t('message.update_success'))
            } catch (err) {
                this.initUserInfo()
                console.error('updateVerify Err', err)
                this.$message.error(err || this.$t('message.update_fail'))
            }
        },
        async initUserInfo () {
            const token = Cookies.get(config.COOKIES.TOKEN)
            if (!token) {
                this.login_status = -1
                return
            }
            this.token = Cookies.get(config.COOKIES.TOKEN)
            try {
                const userResponse = await getUserInfo({})
                const { user = '' } = userResponse.data || {}
                const { tel_num = '', mail = '', status = 0, verify_status = -1 } = user
                if (status === 1) {
                    this.login_data.tel_num = tel_num
                    this.login_data.mail = mail
                    this.login_data.verify_status_computed = (verify_status === 1)
                    this.login_status = 1
                } else {
                    this.login_status = -1
                }
            } catch (err) {
                console.error('getUserInfo Err', err)
                this.$message.error(err || this.$t('wu-fa-huo-qu-yong-hu-xin-xi'))
            }
        }
    }
}
</script>

<style scoped>

</style>
