<template>
    <Layout>
        授权中，请稍后...
    </Layout>
</template>

<script>
import { GetEnmooyUserByCode } from '@/api/enmooy'
import { GetUrlParams } from '@/utils/utils'
import Layout from '@/components/Layout.vue'
import config from '@/config/config.js'
import Cookies from 'js-cookie'
import { InitCorpDetail } from '@/utils/pri'
import { mapState } from 'vuex'
export default {
    name: 'LoginCallback',
    components: { Layout },
    computed: mapState({
        corpDetail: state => state.corpDetail,
        corpMainDomain: state => state.corpMainDomain

    }),
    data () {
        return {
            VUE_APP_PRODUCT: process.env.VUE_APP_PRODUCT,
            dialogCreateMail: {
                visible: false,
                username: '',
                password: ''
            },
            enmooy_user: {},
            createLoading: false
        }
    },
    async mounted () {
        if (process.env.VUE_APP_PRODUCT === 'cuiqiu_domain_pri') {
            try {
                await InitCorpDetail()
            } catch (err) {
                this.$message({ message: err, type: 'error' })
            }
            if (+this.corpMainDomain.id === 0) {
                this.$message({ message: '当前企业未设置主域名，请联系客服设置', type: 'error' })
                return
            }
            this.initUserInfo()
        }
    },
    methods: {
        async initUserInfo () {
            try {
                const { code = '' } = GetUrlParams()
                if (code === '') {
                    throw new Error('code为空，请重新进行授权')
                }
                const { app_id = '', platform = '' } = this.corpDetail
                if (app_id === '' || platform === '') {
                    throw new Error('app_id为空，无法获取app_id')
                }
                const userResponse = await GetEnmooyUserByCode({ code, platform, app_id })
                const { enmooy_user = {}, token_admin = '' } = userResponse.data || {}
                const { open_id = '', admin_status = -1 } = enmooy_user
                if (open_id === '') {
                    throw new Error('无法获取用户信息')
                }
                if (admin_status !== 1 || token_admin === '') {
                    throw new Error('当前账户无管理员权限')
                }
                console.log('token_admin', token_admin)
                Cookies.set(config.COOKIES.TOKEN, token_admin, { domain: config.COOKIES.SET_DOMAIN })
                location.href = config.ADMIN_INDEX
            } catch (err) {
                console.error('initUserInfo err', err)
                this.$message.error(err || '获取用户信息失败')
            }
        }
    }
}
</script>

<style scoped></style>
