<template>
    <Layout>
        <div style="display: flex;justify-content: center;padding:50px 0;align-items: center;width: 100%;">
            <div style="width:80%;" v-if="login_status === -1">

                <!-- 登录 -->
                <p style="text-align:center;padding:10px 0;">{{$t("common.no_login")}}</p>

                <div style="padding:30px 0px;">
                    <router-link :to="{ name: 'login' }">
                        <el-button type="warning" size="medium" style="width:100%;">
                            {{$t("button.login")}}
                        </el-button>
                    </router-link>
                </div>

            </div>
            <div style="width:80%;" v-if="login_status === 1">
                <p style="text-align:center;padding:10px 0;" v-if="VUE_APP_PROJECT==='cuiqiu'">手机号修改</p>
                <div style="padding-bottom:10px;" v-if="VUE_APP_PROJECT==='cuiqiu'">
                    <el-input v-model="update_data_old.tel_num" :placeholder="$t('qing-shu-ru-shou-ji')" :disabled="true">
                        <div slot="prepend">
                            <i class="el-icon-phone" />
                            {{ $t('jiu-shou-ji-hao') }}
                        </div>
                    </el-input>
                </div>

                <p style="text-align:center;padding:10px 0;" v-if="VUE_APP_PROJECT==='getmx'">{{$t('mail_reset.title')}}</p>
                <div style="padding-bottom:10px;" v-if="VUE_APP_PROJECT==='getmx'">
                    <el-input v-model="update_data_old.mail" :placeholder="$t('input.placeholder.mail')" :disabled="true">
                        <div slot="prepend">
                            <i class="el-icon-message" />
                            {{$t('input.lable.mail_old')}}
                        </div>
                    </el-input>
                </div>

                <div style="padding-bottom:10px;">
                    <el-input v-model="update_data_old.verify_code" :placeholder="$t('input.placeholder.verify_code')">
                        <div slot="prepend">
                            <i class="el-icon-message" />
                            {{$t("input.lable.verify_code")}}
                        </div>
                        <div v-if="update_data_old.send_status === 1" slot="append" style="cursor:pointer;">
                            <div style="width:100%;height:100%;" @click="sendCode('update_data_old')">
                                {{$t("button.verify_code_send")}}
                            </div>
                        </div>
                        <div v-if="update_data_old.send_status === 2" slot="append">
                            <i class="el-icon-loading" />
                        </div>
                        <div v-if="update_data_old.send_status === 3" slot="append">
                            {{ update_data_old.send_text }}
                        </div>
                    </el-input>
                </div>

                <div style="padding-bottom:10px;" v-if="VUE_APP_PROJECT==='cuiqiu'">
                    <el-input v-model="update_data_new.tel_num" :placeholder="$t('qing-shu-ru-xin-shou-ji')">
                        <div slot="prepend">
                            <i class="el-icon-phone" />
                            {{ $t('xin-shou-ji-hao') }}
                        </div>
                    </el-input>
                </div>

                <div style="padding-bottom:10px;" v-if="VUE_APP_PROJECT==='getmx'">
                    <el-input v-model="update_data_new.mail" :placeholder="$t('input.lable.mail_new')">
                        <div slot="prepend">
                            <i class="el-icon-message" />
                            {{$t('input.lable.mail_new')}}
                        </div>
                    </el-input>
                </div>

                <div style="padding-bottom:10px;">
                    <el-input v-model="update_data_new.verify_code" :placeholder="$t('input.placeholder.verify_code')">
                        <div slot="prepend">
                            <i class="el-icon-message" />
                            {{$t("input.lable.verify_code")}}
                        </div>
                        <div v-if="update_data_new.send_status === 1" slot="append" style="cursor:pointer;">
                            <div style="width:100%;height:100%;" @click="sendCode('update_data_new')">
                                {{$t("button.verify_code_send")}}
                            </div>
                        </div>
                        <div v-if="update_data_new.send_status === 2" slot="append">
                            <i class="el-icon-loading" />
                        </div>
                        <div v-if="update_data_new.send_status === 3" slot="append">
                            {{ update_data_new.send_text }}
                        </div>
                    </el-input>
                </div>

                <div style="padding-bottom:10px;">
                    <el-button type="primary" size="medium" style="width:100%;" @click="updateTelNumOrMail" v-if="VUE_APP_PROJECT==='cuiqiu'">
                        {{ $t('xiu-gai-shou-ji-hao') }}
                    </el-button>
                    <el-button type="primary" size="medium" style="width:100%;" @click="updateTelNumOrMail" v-if="VUE_APP_PROJECT==='getmx'">
                        {{$t('button.mail_reset')}}
                    </el-button>
                </div>

                <div style="text-align:center;">
                    <router-link :to="{ name: 'login' }">
                        {{$t('button.login')}}
                    </router-link>
                </div>

            </div>
        </div>
        <!-- breadcrumb-area-end -->
    </Layout>
</template>

<script>
import Cookies from 'js-cookie'
import { sendCode, updateTelNumOrMail, getUserInfo } from '@/api/user'
import Layout from '@/components/Layout.vue'
import config from '@/config/config.js'
import { CaptchaCode } from '@/utils/captcha'
import { InitCorpDetail } from '@/utils/pri'
import { IsMail, IsTel } from '@/utils/verify'
export default {
    components: { Layout },
    name: 'ResetPassword',
    data () {
        return {
            VUE_APP_PROJECT: process.env.VUE_APP_PROJECT,
            VUE_APP_PRODUCT: process.env.VUE_APP_PRODUCT,
            // 修改手机号数据
            update_data_old: {
                tel_num: '',
                mail: '',
                verify_code: '',
                send_text: this.$t('button.verify_code_send'),
                send_status: 1,
                send_product: process.env.VUE_APP_PROJECT === 'cuiqiu' ? 'tel-update-old' : 'mail-update-old'
            },
            update_data_new: {
                tel_num: '',
                mail: '',
                verify_code: '',
                send_text: this.$t('button.verify_code_send'),
                send_status: 1,
                send_product: process.env.VUE_APP_PROJECT === 'cuiqiu' ? 'tel-update-new' : 'mail-update-new'
            },
            login_status: 0
        }
    },
    async mounted () {
        if (process.env.VUE_APP_PRODUCT === 'cuiqiu_domain_pri') {
            try {
                await InitCorpDetail()
            } catch (err) {
                this.$message({ message: err, type: 'error' })
            }
        }
        this.initUserInfo()
    },
    methods: {
        async initUserInfo () {
            const token = Cookies.get(config.COOKIES.TOKEN)
            if (!token) {
                this.login_status = -1
                return
            }
            this.token = Cookies.get(config.COOKIES.TOKEN)

            try {
                const userResponse = await getUserInfo({})
                const { user = '' } = userResponse.data || {}
                const { tel_num = '', mail = '', status = 0 } = user
                if (status === 1) {
                    this.update_data_old.tel_num = tel_num
                    this.update_data_old.mail = mail
                    this.login_status = 1
                } else {
                    this.login_status = -1
                }
            } catch (err) {
                console.error('getUserInfo Err', err)
                this.$message.error(err || this.$t('wu-fa-huo-qu-yong-hu-xin-xi'))
            }
        },
        // 修改手机号
        async updateTelNumOrMail () {
            if (this.VUE_APP_PROJECT === 'cuiqiu' && (!IsTel(this.update_data_old.tel_num) || !IsTel(this.update_data_new.tel_num))) {
                this.$message.error(this.$t('shou-ji-ge-shi-bu-zheng-que'))
                return
            }
            if (this.VUE_APP_PROJECT === 'getmx' && (!IsMail(this.update_data_old.mail) || !IsMail(this.update_data_new.mail))) {
                this.$message.error(this.$t('input.verify.mail_format'))
                return
            }
            if (this.update_data_old.verify_code === '' || this.update_data_new.verify_code === '') {
                this.$message.error(this.$t('input.verify.code_empty'))
                return
            }

            try {
                await updateTelNumOrMail({
                    old_tel_num: this.update_data_old.tel_num,
                    old_mail: this.update_data_old.mail,
                    old_verify_code: this.update_data_old.verify_code,
                    old_product: this.update_data_old.send_product,

                    new_tel_num: this.update_data_new.tel_num,
                    new_mail: this.update_data_new.mail,
                    new_verify_code: this.update_data_new.verify_code,
                    new_product: this.update_data_new.send_product
                })
                this.$message.success(this.$t('message.update_success'))
                this.$router.push({ name: 'login' })
            } catch (err) {
                console.error('updateTelNumOrMail Err', err)
                this.$message.error(err || this.$t('message.update_fail'))
            }
        },

        // 倒计时
        lastTime (data) {
            let time = 60
            const lastInterval = setInterval(() => {
                this[data].send_status = 3
                if (time <= 0) {
                    clearInterval(lastInterval)
                    this[data].send_status = 1
                    this[data].send_text = this.$t('button.verify_code_send')
                    return
                }
                time--
                this[data].send_text = `${time}s`
            }, 1000)
        },

        // 发送验证码
        async sendCode (data) {
            this[data].send_status = 2
            if (this.VUE_APP_PROJECT === 'cuiqiu' && !IsTel(this[data].tel_num)) {
                this[data].send_status = 1
                this.$message.error(this.$t('shou-ji-ge-shi-bu-zheng-que'))
                return
            }
            if (this.VUE_APP_PROJECT === 'getmx' && !IsMail(this[data].mail)) {
                this[data].send_status = 1
                this.$message.error(this.$t('input.verify.mail_format'))
                return
            }

            // 验证码
            let captchaData = {}
            try {
                captchaData = await CaptchaCode()
            } catch (err) {
                console.error('CaptchaCode Err:' + err)
                this[data].send_status = 1
                this.$message.error(err || this.$t('message.verify_code_fail'))
                throw err
            }

            const { captcha_id, captcha_value } = captchaData || {}

            try {
                await sendCode({
                    captcha_id,
                    captcha_value,
                    tel_num: this[data].tel_num,
                    mail: this[data].mail,
                    product: this[data].send_product
                })
                this.lastTime(data)
            } catch (err) {
                this[data].send_status = 1
                console.error('getUserInfo err', err)
                this.$message.error(err || this.$t('message.send_code_fail'))
            }
        }
    }
}
</script>

<style scoped>

</style>
