import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state () {
        return {
            corpCss: {},
            corpDetail: {},
            corpMainDomain: {}
        }
    },
    mutations: {
        setCorpCss (state, value) {
            state.corpCss = value
        },
        setCorpDetail (state, value) {
            state.corpDetail = value
        },
        setMainDomain (state, value) {
            state.corpMainDomain = value
        }
    },
    actions: {
    },
    modules: {
    }
})
