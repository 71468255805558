<template>
    <Layout>
        <div
            style="
        display: flex;
        justify-content: center;
        padding: 50px 0;
        align-items: center;
        width: 100%;
      "
        >
            <div style="width: 80%" v-if="login_status === -1">
                <!-- 登录 -->
                <p style="text-align: center; padding: 10px 0">
                    {{ $t("login.title") }}
                </p>
                <div style="padding-bottom: 10px" v-if="VUE_APP_PROJECT === 'cuiqiu'">
                    <el-input
                        v-model="login_data.tel_num"
                        :placeholder="$t('qing-shu-ru-shou-ji')"
                        @blur="checkVerify"
                    >
                        <div slot="prepend">
                            <i class="el-icon-phone" />
                            {{ $t("shou-ji-hao") }}
                        </div>
                    </el-input>
                </div>
                <div style="padding-bottom: 10px" v-if="VUE_APP_PROJECT === 'getmx'">
                    <el-input
                        v-model="login_data.mail"
                        :placeholder="$t('input.placeholder.mail')"
                        @blur="checkVerify"
                    >
                        <div slot="prepend">
                            <i class="el-icon-message" />
                            {{ $t("input.lable.mail") }}
                        </div>
                    </el-input>
                </div>
                <div
                    v-show="login_data.verify_status === 1"
                    style="padding-bottom: 10px"
                >
                    <el-input
                        v-model="login_data.verify_code"
                        :placeholder="$t('input.placeholder.verify_code')"
                    >
                        <div slot="prepend">
                            <i class="el-icon-message" />
                            {{ $t("input.lable.verify_code") }}
                        </div>
                        <div
                            v-if="login_data.send_status === 1"
                            slot="append"
                            style="cursor: pointer"
                        >
                            <div
                                style="width: 100%; height: 100%"
                                @click="sendCode('login_data')"
                            >
                                {{ $t("button.verify_code_send") }}
                            </div>
                        </div>
                        <div v-if="login_data.send_status === 2" slot="append">
                            <i class="el-icon-loading" />
                        </div>
                        <div v-if="login_data.send_status === 3" slot="append">
                            {{ login_data.send_text }}
                        </div>
                    </el-input>
                </div>

                <div style="padding-bottom: 10px">
                    <el-input
                        v-model="login_data.password"
                        :placeholder="$t('input.placeholder.password')"
                        show-password
                    >
                        <div slot="prepend">
                            <i class="el-icon-key" />
                            {{ $t("input.lable.password") }}
                        </div>
                    </el-input>
                </div>

                <div style="text-align: right; padding-bottom: 10px">
                    <router-link :to="{ name: 'password-reset' }">
                        {{ $t("button.forget_password") }}
                    </router-link>
                </div>

                <div style="padding-bottom: 10px">
                    <el-button
                        type="warning"
                        size="medium"
                        style="width: 100%"
                        @click="login"
                    >
                        {{ $t("button.login") }}
                    </el-button>
                </div>

                <div
                    style="text-align: center"
                    v-if="VUE_APP_PRODUCT !== 'cuiqiu_domain_pri'"
                >
                    <router-link :to="{ name: 'regist' }">
                        {{ $t("button.regist_desc") }}
                    </router-link>
                </div>

                <div class="container" v-if="VUE_APP_PRODUCT === 'cuiqiu_domain_pri'">
                    <div class="wrapper">
                        <div class="word">使用第三方账号登录</div>
                    </div>
                </div>
                <div
                    style="padding-top: 10px; padding-bottom: 10px"
                    v-if="VUE_APP_PRODUCT === 'cuiqiu_domain_pri'"
                >
                    <el-button
                        type="primary"
                        size="medium"
                        style="width: 100%"
                        @click="loginByEnmooy"
                    >
                        使用中广智媒账号登录
                    </el-button>
                </div>
            </div>
            <div style="width: 80%" v-if="login_status === 1">
                <!-- 登录 -->
                <p style="text-align: center; padding: 10px 0">
                    {{ $t("common.in_login") }}
                </p>

                <p
                    v-if="VUE_APP_PROJECT === 'cuiqiu'"
                    style="text-align: center; font-size: 30px; color: #e7a23b"
                >
                    {{ login_data.tel_num }}
                </p>
                <p
                    v-if="VUE_APP_PROJECT === 'getmx'"
                    style="text-align: center; font-size: 30px; color: #e7a23b"
                >
                    {{ login_data.mail }}
                </p>

                <div style="padding: 30px 0px">
                    <el-button
                        type="warning"
                        size="medium"
                        style="width: 100%"
                        @click="jumpAdmin"
                    >
                        {{ $t("button.jump_admin") }}
                    </el-button>
                </div>

                <div style="text-align: center">
                    <a @click="tabAcount" style="cursor: pointer">
                        {{ $t("button.tab_account") }}
                    </a>
                </div>
            </div>
        </div>
    <!-- breadcrumb-area-end -->
    </Layout>
</template>

<script>
import Cookies from 'js-cookie'
import { login, sendCode, checkVerify, getUserInfo } from '@/api/user'
import Layout from '@/components/Layout.vue'
import config from '@/config/config.js'
import { CaptchaCode } from '@/utils/captcha'
import { mapState } from 'vuex'
import { InitCorpDetail } from '@/utils/pri'
import qs from 'qs'
import { IsMail, IsTel } from '@/utils/verify'
export default {
    components: { Layout },
    name: 'Login',
    data () {
        return {
            VUE_APP_PROJECT: process.env.VUE_APP_PROJECT,
            VUE_APP_PRODUCT: process.env.VUE_APP_PRODUCT,
            // 登录数据
            login_data: {
                tel_num: '',
                mail: '',
                password: '',
                verify_code: '',
                send_text: this.$t('button.verify_code_send'),
                send_status: 1,
                send_product: 'login',
                verify_status: -1
            },
            login_status: -1
        }
    },
    computed: mapState({
        corpDetail: state => state.corpDetail
    }),
    async mounted () {
        if (process.env.VUE_APP_PRODUCT === 'cuiqiu_domain_pri') {
            try {
                await InitCorpDetail()
            } catch (err) {
                this.$message({ message: err, type: 'error' })
            }
        }
        this.initUserInfo()
    },
    methods: {
        jumpRef (token) {
            setTimeout(() => {
                const { ref = '' } = qs.parse(location.href.split('?')[1])
                if (ref !== '') {
                    console.log('ref', ref.replace(/__TOKEN__/g, token))
                    location.href = ref.replace(/__TOKEN__/g, token)
                    return
                }
                location.href = config.ADMIN_INDEX
            }, 500)
        },
        jumpAdmin () {
            window.location.href = config.ADMIN_INDEX
        },
        async checkVerify () {
            if (this.VUE_APP_PROJECT === 'cuiqiu' && !IsTel(this.login_data.tel_num)) {
                return
            }
            if (this.VUE_APP_PROJECT === 'getmx' && !IsMail(this.login_data.mail)) {
                return
            }
            try {
                const checkResponse = await checkVerify({
                    tel_num: this.login_data.tel_num,
                    mail: this.login_data.mail
                })
                const { verify_status = 1 } = checkResponse.data || {}
                if (verify_status === 1) { this.login_data.verify_status = 1 }
            } catch (err) {
                console.error('checkVerify err', err)
                this.$message.error(err || this.$t('yan-zheng-shi-bai'))
            }
        },
        // 通过中广智媒登录
        loginByEnmooy () {
            const { app_id = '' } = this.corpDetail || {}
            if (app_id === '') {
                this.$message.error('请先填写登录主体')
                location.reload()
            }

            // 跳转至中广智媒
            const callbackURL = `${config.AUTH_CALLBACK_URL}/?platform=enmooy&app_id=${app_id}#/login-callback`
            const jumpURL = `${config.ENMOOY_URL}/authen?response_type=code&client_id=${app_id}&state=STATE&callback_uri=${encodeURIComponent(callbackURL)}`
            location.href = jumpURL
        },
        async initUserInfo () {
            const token = Cookies.get(config.COOKIES.TOKEN)
            if (!token) return
            this.token = Cookies.get(config.COOKIES.TOKEN)
            try {
                const response = await getUserInfo({})
                const { user = '' } = response.data || {}
                const { tel_num = '', mail = '', status = 0 } = user
                if (status === 1) {
                    this.login_data.tel_num = tel_num
                    this.login_data.mail = mail
                    this.login_status = 1
                } else {
                    this.tabAcount()
                }
            } catch (err) {
                console.error('getUserInfo err', err)
                this.$message.error(err || this.$t('wu-fa-huo-qu-yong-hu-xin-xi'))
            }
        },
        // 退出
        tabAcount () {
            Cookies.remove(config.COOKIES.LOGIN_TEL_NUM_MAIL, { domain: config.COOKIES.SET_DOMAIN })
            Cookies.remove(config.COOKIES.TOKEN, { domain: config.COOKIES.SET_DOMAIN })
            this.login_status = -1
        },
        // 登录
        async login () {
            let userName = ''
            let subUserName = ''
            if (this.VUE_APP_PROJECT === 'cuiqiu') {
                ({ userName, subUserName } = this.getSubUser(this.login_data.tel_num))
                if (this.login_data.tel_num.startsWith('6311046')) {
                    this.$alert('账号未绑定手机号，存在被盗风险，已限制登录，请联系客服绑定手机号', '提示', {
                        dangerouslyUseHTMLString: true,
                        type: 'error',
                        showCancelButton: false
                    })
                }
                if (!IsTel(userName)) {
                    this.$message.error(this.$t('shou-ji-ge-shi-bu-zheng-que'))
                    return
                }
            }
            if (this.VUE_APP_PROJECT === 'getmx') {
                ({ userName, subUserName } = this.getSubUser(this.login_data.mail))
                if (!IsMail(userName)) {
                    this.$message.error(this.$t('input.verify.mail_format'))
                    return
                }
            }
            if (this.login_data.password === '') {
                this.$message.error(this.$t('input.verify.password_empty'))
                return
            }

            // 验证码
            let captchaData = {}
            try {
                captchaData = await CaptchaCode()
            } catch (err) {
                console.error('CaptchaCode Err:' + err)
                this.login_data.send_status = 1
                this.$message.error(err || this.$t('message.verify_code_fail'))
                throw err
            }

            const { captcha_id, captcha_value } = captchaData
            try {
                const loginResponse = await login({
                    tel_num: this.VUE_APP_PROJECT === 'cuiqiu' ? userName : '',
                    mail: this.VUE_APP_PROJECT === 'getmx' ? userName : '',
                    sub_user_name: subUserName,
                    verify_code: this.login_data.verify_code,
                    password: this.login_data.password,
                    captcha_id,
                    captcha_value,
                    product: this.login_data.send_product
                })
                const { token = '' } = loginResponse.data || {}
                if (token === '') {
                    throw new Error(loginResponse.msg)
                }
                this.$message.success(this.$t('message.login_success'))
                Cookies.set(config.COOKIES.TOKEN, token, { domain: config.COOKIES.SET_DOMAIN })
                this.jumpRef(token)
            } catch (err) {
                console.error('login Err', err)
                this.$message.error(err || this.$t('message.login_fail'))
            }
        },
        getSubUser (accountString) {
            // 使用冒号拆分字符串
            const parts = accountString.split(':')

            // 检查拆分后的数组长度
            if (parts.length === 2) {
                return {
                    userName: parts[0], // 前半部分可能是手机号或邮箱
                    subUserName: parts[1] // 后半部分是子账号名称
                }
            } return {
                userName: accountString,
                subUserName: 'admin'
            }
        },
        // 倒计时
        lastTime (data) {
            let time = 60
            const lastInterval = setInterval(() => {
                this[data].send_status = 3
                if (time <= 0) {
                    clearInterval(lastInterval)
                    this[data].send_status = 1
                    this[data].send_text = this.$t('button.verify_code_send')
                    return
                }
                time--
                this[data].send_text = `${time}s`
            }, 1000)
        },

        // 发送验证码
        async sendCode (data) {
            this[data].send_status = 2
            if (this.VUE_APP_PROJECT === 'cuiqiu' && !IsTel(this[data].tel_num)) {
                this[data].send_status = 1
                this.$message.error(this.$t('shou-ji-ge-shi-bu-zheng-que'))
                return
            }
            if (this.VUE_APP_PROJECT === 'getmx' && !IsMail(this[data].mail)) {
                this[data].send_status = 1
                this.$message.error(this.$t('input.verify.mail_format'))
                return
            }

            // 验证码
            let captchaData = {}
            try {
                captchaData = await CaptchaCode()
            } catch (err) {
                console.error('CaptchaCode Err:' + err)
                this[data].send_status = 1
                this.$message.error(err || this.$t('message.verify_code_fail'))
                throw err
            }

            const { captcha_id, captcha_value } = captchaData || {}

            try {
                await sendCode({
                    captcha_id,
                    captcha_value,
                    tel_num: this[data].tel_num,
                    mail: this[data].mail,
                    product: this[data].send_product
                })
                this.lastTime(data)
            } catch (err) {
                this[data].send_status = 1
                console.error('getUserInfo err', err)
                this.$message.error(err || this.$t('message.send_code_fail'))
            }
        }
    }
}
</script>

<style scoped>
.container {
  position: relative;
  width: 100%;
  margin: 20px auto 0 auto;
}
.wrapper {
  text-align: center;
  width: 100%;
}
.wrapper .word {
  color: #303133;
  font-size: 14px;
}
.wrapper .word:before,
.wrapper .word:after {
  position: absolute;
  top: 50%;
  width: 30%;
  height: 1px;
  background: #dcdfe6;
  content: "";
}

/* 调整背景横线的左右距离 */
.wrapper .word::before {
  left: 0;
}
.wrapper .word::after {
  right: 0;
}
</style>
