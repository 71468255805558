<template>
    <!-- :style="{ backgroundImage: 'url(' + corpCss.bg + ')' }" -->
    <div class="bg">
        <el-row :gutter="0" justify="center" align="middle" type="flex" class="container">
            <el-col :xs="0" :sm="8" :md="8" :lg="8" :xl="8">
                <div class="left">
                    <h2>{{ corpCss.corp_name }}</h2>
                    <p>{{$t("layout.title_child")}}</p>
                </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <div class="right" style="position: relative;">
                    <div style="position: absolute;right: 20px;top: 20px;color: #909399;font-size: 10px;text-decoration: underline;cursor: pointer;" @click="tabCorp" v-if="VUE_APP_PRODUCT === 'cuiqiu_domain_pri'">切换主体</div>
                    <slot/>
                </div>
            </el-col>
        </el-row>
        <div class="bottom" v-if="VUE_APP_PRODUCT === 'cuiqiu_auth'"><a href="https://cuiqiu.com" >脆球邮箱官网</a></div>
        <div class="bottom" v-if="VUE_APP_PRODUCT === 'cuiqiu_auth_pri'"><a href="https://cuiqiu.com" >脆球邮箱提供技术支持</a></div>
        <div class="bottom" v-if="VUE_APP_PRODUCT === 'getmx_auth'"><a href="https://getmx.com" >GetMX Domain E-Mail</a></div>

        <whatsapp-button v-if="VUE_APP_PROJECT === 'getmx'" />
        <weixin-button v-if="VUE_APP_PROJECT === 'cuiqiu'" />
    </div>

</template>

<script>
import { mapState } from 'vuex'
import Cookies from 'js-cookie'
import config from '@/config/config.js'
import whatsappButton from '@/components/whatsapp-button/whatsapp-button'
import weixinButton from '@/components/weixin-button/weixin-button'
export default {
    name: 'Layout',
    props: {
        msg: String
    },
    components: {
        whatsappButton,
        weixinButton
    },
    computed: mapState({
        corpCss: state => state.corpCss
    }),
    data () {
        return {
            VUE_APP_TITLE: process.env.VUE_APP_TITLE,
            VUE_APP_PROJECT: process.env.VUE_APP_PROJECT,
            VUE_APP_PRODUCT: process.env.VUE_APP_PRODUCT
        }
    },
    methods: {
        tabCorp () {
            Cookies.remove(config.COOKIES.CORP_ID)
            Cookies.remove(config.COOKIES.LOGIN_TEL_NUM_MAIL)
            Cookies.remove(config.COOKIES.TOKEN)
            const url = `${location.origin}${location.pathname}`
            location.href = url
        }
    }
}
</script>

<style scoped>
.bg{
    width: 100vw;
    height: 100vh;
    background: url("~assets/bg.jpg") center center no-repeat;
    background-size: cover;
    background-repeat:no-repeat;
    background-attachment:fixed;
}

.container{
    height: 100vh;
}
.left{
    background: rgba(255,255,255,0.4);
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.right{
    background: #fff;
    height: 380px;
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bottom{
    position: fixed;
    bottom: 20px;
    text-align: center;
    width: 100%;
    height: 30px;
    left: 0;
}
</style>
