import axios from 'axios'
import { GetUrlParams } from '@/utils/utils'
import qs from 'qs'
import Cookies from 'js-cookie'
import config from '@/config/config.js'
import { Message, MessageBox } from 'element-ui'
import { GetCorpDetail } from '@/api/corp'
import store from '@/store'
axios.defaults.withCredentials = true
// ADMIN获取企业基本信息
export async function GetCorpDetailAdmin () {
    try {
        // 拼接请求参数
        const params = {
            host: location.hostname
        }
        const { corp_id = '', corp_key = '', corp_name = '', platform = '', app_id = '' } = GetUrlParams()
        if (corp_id !== '') {
            params.corp_id = corp_id
        }
        if (corp_key !== '') {
            params.corp_key = corp_key
        }
        if (corp_name !== '') {
            params.corp_name = corp_name
        }
        if (platform !== '' && app_id !== '') {
            params.platform = platform
            params.app_id = app_id
        }

        // 获取企业信息
        const res = await axios({
            method: 'post',
            url: config.PRI_REQUEST_API,
            data: qs.stringify(params)
        })
        return res.data.data.info
    } catch (err) {
        console.error('GetAdminCorpDetail Err' + err)
        throw err
    }
}

// 初始化企业信息
export async function InitCorpDetail () {
    try {
        const { app_id = '', id: store_crop_id = '' } = store.state.corpDetail || {}
        if (app_id !== '' && store_crop_id !== '') {
            console.log('store中存在企业信息', app_id, store_crop_id)
            return store_crop_id
        }
        // cookie存在ID 并且URL用户没有强制企业信息
        const { corp_id = '', corp_key = '', corp_name = '' } = GetUrlParams()
        const corpIDCookies = Cookies.get(config.COOKIES.CORP_ID)
        if (corp_id === '' && corp_key === '' && corp_name === '') {
            if (corpIDCookies && +corpIDCookies !== 0) {
                console.log('cookie中存在企业信息', corpIDCookies)
                await initCorpUI(corpIDCookies)
                return corpIDCookies
            }
        }

        // 获取ID
        const corpDetailAdmin = await GetCorpDetailAdmin()
        const { id = '' } = corpDetailAdmin || {}
        if (id === '' || +id === 0) {
            store.commit('setCorpCss', {
                corp_name: process.env.VUE_APP_TITLE,
                logo: '',
                bg: require('../assets/bg.jpg')
            })
            inputCorp()
            if ((!corpIDCookies || +corpIDCookies === 0) && corp_id === '' && corp_key === '' && corp_name === '') {
                throw new Error('请输入企业信息')
            } else {
                throw new Error('无法获取企业信息，请稍后再试，或联系管理员')
            }
        }
        Cookies.set(config.COOKIES.CORP_ID, id, { domain: config.COOKIES.SET_DOMAIN })
        await initCorpUI(id)
        return id
    } catch (err) {
        console.error('InitCorpDetail Err' + err)
        throw err
    };
}

// 初始化企业UI
async function initCorpUI (corp_id) {
    const corpDetail = await GetCorpDetail({ corp_id })
    const { corp = {}, main_domain = {} } = corpDetail.data || {}
    store.commit('setCorpDetail', corp)
    store.commit('setMainDomain', main_domain)
    const { oem = '{}' } = corp || {}
    const { css = {} } = JSON.parse(oem)
    store.commit('setCorpCss', css)
    document.title = `${document.title}-${css.corp_name}`
}

// 获取请求URL
export async function GetRequestAPI () {
    try {
        // 测试环境直接返回
        if (process.env.NODE_ENV === 'development') {
            return config.API_URL
        }

        const { corp_id: corpIDParam = '' } = GetUrlParams()
        let corpIDCookies = Cookies.get(config.COOKIES.CORP_ID)

        // 用户切换企业
        if (corpIDCookies && corpIDCookies !== '' && corpIDParam && corpIDParam !== '' && corpIDParam !== corpIDCookies) {
            corpIDCookies = ''
            Cookies.remove(config.COOKIES.CORP_ID)
        }

        // Cookie中已经保存企业信息
        if (corpIDCookies && corpIDCookies !== '') {
            // 用户手动更改ID需重新更新API接口
            console.log('存在', corpIDCookies)
            return process.env.NODE_ENV === 'test' ? 'https://g-' + corpIDCookies + '-test.cuiqiu.cn/api' : 'https://g-' + corpIDCookies + '.cuiqiu.cn/api'
        }
        console.log('不存在', corpIDCookies)
        const { id = '' } = await GetCorpDetailAdmin()
        if (id === '') {
            throw new Error('无法获取企业信息，请稍后再试，或联系管理员')
        }
        Cookies.set(config.COOKIES.CORP_ID, id, { domain: config.COOKIES.SET_DOMAIN })
        return process.env.NODE_ENV === 'test' ? 'https://g-' + id + '-test.cuiqiu.cn/api' : 'https://g-' + id + '.cuiqiu.cn/api'
    } catch (err) {
        console.error('InitCorpDetail Err' + err)
        throw err
    };
}

// 输入企业地址
function inputCorp () {
    MessageBox.prompt('请输入组织机构，快速设置服务器', '填写组织机构', {
        confirmButtonText: '确定',
        showCancelButton: false,
        closeOnHashChange: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
                done()
                return
            }
            Message({ message: '请填写组织信息', type: 'error' })
        }
    }).then(({ value }) => {
        if (!value || value === '') {
            Message({ message: '输入为空', type: 'error' })
            inputCorp()
            return
        }
        let param = {}
        const index = location.search.indexOf('?')
        if (index >= 0) {
            param = qs.parse(location.search.slice(index + 1))
        }
        param.corp_name = value
        if (param.corp_id) {
            delete param.corp_id
        }
        const url = `${location.origin}${location.pathname}?${qs.stringify(param)}${location.hash}`
        location.href = url
    }).catch(() => {
        Message({ message: '无法获取企业信息，请稍后再试，或联系管理员', type: 'error' })
    })
}
