<template>
    <Layout v-if="VUE_APP_PRODUCT !== 'cuiqiu_domain_pri'">
        <div style="display: flex;justify-content: center;padding:50px 0;align-items: center;width: 100%;">
            <div style="width:80%;">

                <!-- 注册 -->
                <p style="text-align:center;padding:10px 0;">{{$t("regist.title")}}</p>
                <div>
                    <div style="padding-bottom:10px;">
                        <el-input v-model="regist_data.tel_num" :placeholder="$t('qing-shu-ru-shou-ji')" v-if="VUE_APP_PROJECT==='cuiqiu'">
                            <div slot="prepend">
                                <i class="el-icon-phone" />
                                {{ $t('shou-ji-hao') }}
                            </div>
                        </el-input>
                    </div>

                    <div style="padding-bottom:10px;">
                        <el-input v-model="regist_data.mail" :placeholder="$t('input.placeholder.mail')" v-if="VUE_APP_PROJECT==='getmx'">
                            <div slot="prepend">
                                <i class="el-icon-message" />
                                {{$t("input.lable.mail")}}
                            </div>
                        </el-input>
                    </div>

                    <div style="padding-bottom:10px;">
                        <el-input v-model="regist_data.verify_code" :placeholder="$t('input.placeholder.verify_code')">
                            <div slot="prepend">
                                <i class="el-icon-message" />
                                {{$t("input.lable.verify_code")}}
                            </div>
                            <div v-if="regist_data.send_status === 1" slot="append" style="cursor:pointer;">
                                <div style="width:100%;height:100%;" @click="sendCode('regist_data')">
                                    {{$t('button.verify_code_send')}}
                                </div>
                            </div>
                            <div v-if="regist_data.send_status === 2" slot="append">
                                <i class="el-icon-loading" />
                            </div>
                            <div v-if="regist_data.send_status === 3" slot="append">
                                {{ regist_data.send_text }}
                            </div>
                        </el-input>
                    </div>

                    <div style="padding-bottom:10px;">
                        <el-input v-model="regist_data.password_1" :placeholder="$t('input.placeholder.password')" show-password>
                            <div slot="prepend">
                                <i class="el-icon-key" />
                                {{$t('input.placeholder.password')}}
                            </div>
                        </el-input>
                    </div>

                    <div style="padding-bottom:10px;">
                        <el-input v-model="regist_data.password_2" :placeholder="$t('input.placeholder.password_2')" show-password>
                            <div slot="prepend">
                                <i class="el-icon-key" />
                                {{$t('input.placeholder.password_2')}}
                            </div>
                        </el-input>
                    </div>

                    <div style="padding-bottom:10px;font-size: 14px;color: #606266;" v-if="VUE_APP_PROJECT==='cuiqiu'">
                        <el-checkbox v-model="agreement" size="mini"></el-checkbox>
                        我已阅读并同意 <a target="_blank" href="https://cuiqiu.com/agreement">脆球邮箱用户协议及隐私协议 </a>
                    </div>

                    <div style="padding-bottom:10px;">
                        <el-button type="success" size="medium" style="width:100%;" @click="regist">
                            {{$t("button.regist")}}
                        </el-button>
                    </div>

                    <div style="text-align:center;">
                        <router-link :to="{ name: 'login' }">
                            {{$t("button.login_desc")}}
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
        <!-- breadcrumb-area-end -->
    </Layout>
</template>

<script>
import Cookies from 'js-cookie'
import { sendCode, regist, checkUserLocal } from '@/api/user'
import Layout from '@/components/Layout.vue'
import config from '@/config/config.js'
import { CaptchaCode } from '@/utils/captcha'
import { IsMail } from '@/utils/verify'
export default {
    components: { Layout },
    name: 'Regist',
    data () {
        return {
            VUE_APP_PROJECT: process.env.VUE_APP_PROJECT,
            VUE_APP_PRODUCT: process.env.VUE_APP_PRODUCT,
            // 注册数据
            regist_data: {
                tel_num: '',
                mail: '',
                verify_code: '',
                password_1: '',
                password_2: '',
                send_text: this.$t('button.verify_code_send'),
                send_status: 1,
                send_product: 'regist'
            },
            agreement: true
        }
    },
    mounted () {
    },
    methods: {
        // 检查用户所在区域
        async checkUserLocal () {
            if (this.VUE_APP_PROJECT === 'cuiqiu' && this.regist_data.tel_num !== '') {
                try {
                    const { data = {} } = await checkUserLocal({
                        tel_num: this.regist_data.tel_num,
                        mail: this.regist_data.mail
                    })
                    // console.log('data', data)
                    const { cn = 1, msg = '', url = '' } = data || {}
                    if (cn === -1) {
                        this.$alert(msg, 'Tips', {
                            dangerouslyUseHTMLString: true,
                            type: 'error',
                            showCancelButton: false,
                            callback: action => {
                                if (action === 'confirm') {
                                    window.location.href = url
                                }
                            }
                        })
                    }
                } catch (err) {
                    console.error('checkUserLocal Err', err)
                }
            }
        },
        // 注册
        async regist () {
            if (this.VUE_APP_PROJECT === 'cuiqiu') {
                if (!this.agreement) {
                    this.$message.error('请同意用户协议以及隐私协议后注册')
                    return
                }
                // if (!IsTel(this.regist_data.tel_num)) {
                //     this.$message.error(this.$t('shou-ji-ge-shi-bu-zheng-que'))
                //     return
                // }
            }
            if (this.VUE_APP_PROJECT === 'getmx') {
                if (!IsMail(this.regist_data.mail)) {
                    this.$message.error(this.$t('input.verify.mail_format'))
                    return
                }
            }

            if (this.regist_data.password_1 === '') {
                this.$message.error(this.$t('input.verify.password_empty'))
                return
            }
            if (this.regist_data.password_1 !== this.regist_data.password_2) {
                this.$message.error(this.$t('input.verify.password_diff'))
                return
            }

            try {
                await regist({
                    tel_num: this.regist_data.tel_num,
                    mail: this.regist_data.mail,
                    verify_code: this.regist_data.verify_code,
                    password: this.regist_data.password_1,
                    invite_user: Cookies.get(config.COOKIES.INVITE) || 0,
                    location: Cookies.get(config.COOKIES.LOCATION),
                    referrer: Cookies.get(config.COOKIES.REFERRER),
                    product: 'regist'
                })
                this.$message.success(this.$t('message.regist_success_desc'))
                this.$router.push({ name: 'login' })
            } catch (err) {
                console.error('regist Err', err)
                this.$message.error(err || this.$t('message.regist_fail'))
            }
        },

        // 倒计时
        lastTime (data) {
            let time = 60
            const lastInterval = setInterval(() => {
                this[data].send_status = 3
                if (time <= 0) {
                    clearInterval(lastInterval)
                    this[data].send_status = 1
                    this[data].send_text = this.$t('button.verify_code_send')
                    return
                }
                time--
                this[data].send_text = `${time}s`
            }, 1000)
        },

        // 发送验证码
        async sendCode (data) {
            this[data].send_status = 2
            // if (this.VUE_APP_PROJECT === 'cuiqiu' && !IsTel(this[data].tel_num)) {
            //     this[data].send_status = 1
            //     this.$message.error(this.$t('shou-ji-ge-shi-bu-zheng-que'))
            //     return
            // }
            if (this.VUE_APP_PROJECT === 'getmx' && !IsMail(this[data].mail)) {
                this[data].send_status = 1
                this.$message.error(this.$t('input.verify.mail_format'))
                return
            }

            // 验证码
            let captchaData = {}
            try {
                captchaData = await CaptchaCode()
            } catch (err) {
                console.error('CaptchaCode Err:' + err)
                this[data].send_status = 1
                this.$message.error(err || this.$t('message.verify_code_fail'))
                throw err
            }

            const { captcha_id, captcha_value } = captchaData || {}

            try {
                const sendCodeResponse = await sendCode({
                    captcha_id,
                    captcha_value,
                    tel_num: this[data].tel_num,
                    mail: this[data].mail,
                    product: this[data].send_product
                })

                // 校验用户地区
                const { cn = 1, msg = '', url = '' } = sendCodeResponse.data || {}
                if (cn === -1) {
                    this.$alert(msg, 'Tips', {
                        dangerouslyUseHTMLString: true,
                        type: 'error',
                        showCancelButton: false,
                        callback: action => {
                            if (action === 'confirm') {
                                window.location.href = url
                            }
                        }
                    })
                    this[data].send_status = 1
                    this.$message.error(this.$t('message.send_code_fail'))
                    return
                }

                this.lastTime(data)
            } catch (err) {
                this[data].send_status = 1
                console.error('getUserInfo err', err)
                this.$message.error(err || this.$t('message.send_code_fail'))
            }
        }
    }
}
</script>

<style scoped>

</style>
